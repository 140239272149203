import { createRouter, createWebHistory } from "vue-router";
import Index from "../views/Index.vue";
import store from "../store/index";

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  //{
  //  path: "/:catchAll(.*)",
  // redirect: "/",
  //},
  {
    path: "/explore",
    name: "Explore",
    beforeEnter: (to, from, next) => {
      if (store.getters.is_authenticated) {
        next();
      } else {
        next({ path: "/auth" });
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Explore" */ "../views/Explore.vue"),
  },
  {
    path: "/auth",
    name: "Auth",
    beforeEnter: (to, from, next) => {
      if (!store.getters.is_authenticated) {
        next();
      } else {
        next({ path: "/u/" + store.getters.get_user_data.username });
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Auth" */ "../views/Auth.vue"),
  },
  {
    path: "/u/:username",
    name: "Profile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Profile" */ "../views/Profile.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Contact" */ "../views/Contact.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "About" */ "../views/About.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "PrivacyPolicy" */ "../views/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/terms-of-use",
    name: "TermsOfUse",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TermsOfUse" */ "../views/TermsOfUse.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TermsOfUse" */ "../views/Blog.vue"),
  },
  {
    path: "/blog/hedef-belirleyemiyorum-cok-kararsizim-ne-yapmaliyim",
    name: "Blog1",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "TermsOfUse" */ "../views/Blog/Blog1.vue"),
  },
  {
    path: "/premium",
    name: "Premium",
    beforeEnter: (to, from, next) => {
      if (store.getters.is_authenticated) {
        next();
      } else {
        next({ path: "/auth" });
      }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Premium" */ "../views/Premium.vue"),
  },
  // Yönlendirmeler
  { path: "/u/", redirect: "/" },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
