<template>
    <div class="headerComponent">
        <nav>
            <ul class="logo">
                <router-link :to="'/'" class="text-decoration-none" id="routerlink">
                    <img class="logo_svg" src="@/assets/Images/Header/logo.svg" alt="Hedefly">
                    <span>Beta</span>
                </router-link>
            </ul>
            <ul class="nav-links">
                <router-link v-if="$store.getters.is_authenticated" :to="'/'" class="text-decoration-none"
                    id="routerlink">
                    <img class="home-icon" src="@/assets/Images/Header/home.svg" alt="">
                </router-link>
                <div v-if="!$store.getters.is_authenticated" @click="$store.commit('go_login')" class="btn-1">GİRİŞ
                </div>
                <div v-if="!$store.getters.is_authenticated" @click="$store.commit('go_register')" class="btn-1">
                    Ücretsiz
                    KAYIT OL</div>
                <router-link v-if="$store.getters.is_authenticated && false" :to="'/premium'"
                    class="text-decoration-none">
                    <Vue3Lottie :animationData="premium" :height="70" :width="70" />
                </router-link>
                <router-link v-if="$store.getters.is_authenticated" :to="'/explore'" class="text-decoration-none">
                    <div class="btn-1">Keşfet</div>
                </router-link>
                <div v-if="$store.getters.is_authenticated" class="account">
                    <router-link class="text-decoration-none" :to="'/u/' + $store.getters.get_user_data.username">
                        <img class="person-icon" src="@/assets/Images/Header/person.svg" alt="">
                        <!-- <Vue3Lottie :animationData="account" :height="70" :width="70" /> -->
                    </router-link>

                </div>
                <div v-if="$store.getters.is_authenticated" @click="$store.commit('log_out')" class="btn-1">ÇIKIŞ</div>
            </ul>
        </nav>
    </div>
</template>
<script>
import { Vue3Lottie } from 'vue3-lottie'
import account from '@/assets/Images/Header/account.json'
import premium from '@/assets/Images/Header/premium.json'
export default {
    data() {
        return {
            account,
            premium
        }
    },
    components: {
        Vue3Lottie
    },
    methods: {

    },
    created() {
    }
}
</script>
<style scoped>
.headerComponent {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    filter: alpha(opacity=50);
    -moz-opacity: 0.9;
    -khtml-opacity: 0.9;
    opacity: 0.9;
    background: -webkit-linear-gradient(45deg, rgb(123, 44, 191, .2), rgb(255, 109, 0, .2) 60%);
    border-radius: 0 0 24px 24px;
}

nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 90px;
    padding: 0px 48px;
}

.logo {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.logo span {
    color: #495057;
    text-decoration: none;
    font-size: 12px;
}

.logo_svg {
    width: 180px;
}

.nav-links {
    display: flex;
    flex-direction: row;
    gap: 24px;
    justify-content: center;
    align-items: center;
}

.nav-links .account {
    cursor: pointer;
}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

}

.home-icon,
.person-icon {
    padding-top: 6px;
}

@media screen and (max-width: 730px) {
    nav {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100px;
    }

    .logo_svg {
        width: 120px;
    }

    .btn-1 {
        width: 110px;
        height: 30px;
        text-align: center;
        padding: 0px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        font-size: 12px;
    }

    .nav-links {
        height: 30px;
        gap: 10px;
    }

}
</style>